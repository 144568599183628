<template>
	<div>
		<div v-if="transactions.length > 0">
			<table class="transactions" v-for="(item, index) in transactions" :key="index">
				<!-- <tr>
					<th>{{ languageStrings.casinoId }}</th>
					<td>{{ item.casinoId }}</td>
				</tr> -->
				<tr>
					<th>{{ languageStrings.fromPlayerCash }}</th>
					<td>{{ systemCurrencyTool.formatCurrency(item.fromPlayerCashCOC, systemCurrencyTool.displayType.minorOrFull) }}</td>
				</tr>
				<tr>
					<th>{{ languageStrings.status }}</th>
					<td>{{ item.status }}</td>
				</tr>
				<tr>
					<th>{{ languageStrings.initiationDate }}</th>
					<td v-if="item.initiationDate">{{ new Date(item.initiationDate).toLocaleTimeString(this.localeString, dateOptions) }}</td>
					<td v-else></td>
				</tr>
				<tr>
					<th>{{ languageStrings.offerExpirationDate }}</th>
					<td v-if="item.offerExpirationDate">{{ new Date(item.offerExpirationDate).toLocaleTimeString(this.localeString, dateOptions) }}</td>
					<td v-else></td>
				</tr>
				<tr>
					<th>{{ languageStrings.provider }}</th>
					<td>{{ item.provider }}</td>
				</tr>
				<tr>
					<th>{{ languageStrings.statusAdditionalInfo }}</th>
					<td>{{ item.statusAdditionalInfo }}</td>
				</tr>
			</table>
		</div>
		<h2 v-else>{{ languageStrings.noTransactionsFound }}</h2>
	</div>
</template>

<script>
export default {
	name: "TransactionsTableMobile",
	props: {
		systemCurrencyTool: Object,
		systemSettings: Object,
		transactions: Array,
		languageStrings: Object,
	},
	methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2,
h3 {
	text-align: center;
}

.transactions {
	position: relative;
	width: 90%;
	margin: 0 auto 30px;
	border-collapse: collapse;
	text-align: center;
	background-color: #d7d7d7;
	box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%);
	box-sizing: border-box;
	border: 1px #000 solid;
}

.transactions th {
	background-color: black;
	color: white;
	padding: 8px 2px;
	width: 50%;
}

.transactions tr {
	transition: background-color 0.3s;
}

.transactions tr:nth-child(1n) td {
	background-color: #fff;
	color: #000;
}

.transactions tr:nth-child(2n) td {
	background-color: #bbb;
	color: #000;
}

button.btn {
	display: block;
}

td::first-letter {
    text-transform: capitalize;
}

tr td {
	background-image: URL("../../public/styleOverrides/encore_online_pixel_bg.png") !important;
	background-size: cover;
	color: white !important;
}
</style>
